require('./bootstrap');
import html2canvas from 'html2canvas';
import saveAs from 'file-saver';

document.addEventListener("DOMContentLoaded", function(event) {

    // check page
    let check = document.querySelector('#page-name');
    if (check) {
        let page = check.getAttribute('data-page');

        switch(page) {
            case 'report':

                document.querySelector('[data-act="downloadAvatar"]').addEventListener('click', () => {

                    $('.main-box').css('box-shadow', 'none')
                    $('.btn').hide()

                    html2canvas(document.querySelector("#mainReport"), {allowTaint: true}).then(canvas => {
                        canvas.toBlob(function(blob) {
                            saveAs(blob, 'my_report.jpg');
                            $('.main-box').css('box-shadow', '5px 5px 15px 0px #666666')
                            $('.btn').show()
                        });
                    });

                });

                break;
        }
    }
});
